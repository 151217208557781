import { ERRORS } from "../../../constants/strings"
import { toJS } from "mobx"
import Asset from "../../models/asset/Asset"
import { api_post_product, api_get_product_by_id, api_update_product, } from "../../../queries/products"
import { api_get_merchant_storefront, api_update_merchant_storefront, api_update_merchant_location, api_get_delivery_zones, api_add_delivery_zone, api_remove_delivery_zone, api_update_delivery_zone, api_get_all_delivery_zones } from "../../../queries/merchants"
import { ToasterMsgs } from "../../../constants/toasterMsgs"
import { toastToError, toastToSuccess } from "../../../services/notification-service"
import { parse } from "date-fns"

export const settingsActions = self => ({
  resetProduct(id) {
    self.set('product', id)
  },
  validLegalName() {
    if (self.legalName === null || self.legalName.length < 1 ) self.set('legalNameError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('legalNameError', null)
  },
  validLicenceNumber() {
    if (self.licenceNumber === null || self.licenceNumber.length < 1 ) self.set('licenceNumberError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('licenceNumberError', null)
  },
  validTaxId() {
    if (self.taxId === null || self.taxId.length < 1 ) self.set('taxIdError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('taxIdError', null)
  },
  validEmail() {
    if (self.email === null || self.email.length < 1  ) self.set('emailError', ERRORS.ITEM_TAX_RATE_ERROR)
    else self.set('emailError', null)
  },
  validPhone() {
    if (self.phone === null || self.phone.lenght < 1 ) self.set('phoneError', ERRORS.ITEM_THC_ERROR)
    else self.set('phoneError', null)
  },
  validDeliveryCommission() {
    if (self.deliveryCommision === null || self.deliveryCommision.length < 1 ) self.set('deliveryCommisionError', ERRORS.ITEM_CBD_ERROR)
    else self.set('deliveryCommissionError', null)
  },
  validPickupCommision() {
    if (self.pickupCommision === null || self.pickupCommision.length < 1 ) self.set('pickupCommisionError', ERRORS.ITEM_CBD_ERROR)
    else self.set('pickupCommisionError', null)
  },
  clearInputs() {
    self.set('legalNameError', null)
    self.set('emailError', null)
    self.set('phoneError', null)
    self.set('deliveryCommissionError', null)
    self.set('pickupCommisionError', null)
  },
  async updateStorefront() {
    try {
      self.set('loading', true)

      const dataMerchant = {
        legalName: self.legalName,
        phone: self.phone,
        email: self.email,
        taxId: self.taxId,
        licenceNumber: self.licenceNumber,
        notificationEmail: self.notificationEmail
      }
      const dataAdmin = {
        legalName: self.legalName,
        phone: self.phone,
        email: self.email,
        taxId: self.taxId,
        licenceNumber: self.licenceNumber,
        deliveryCommission: self.deliveryCommission,
        notificationEmail: self.notificationEmail,
        realTimeDeliveryEstimation: self.realTimeDeliveryEstimation,
        additionalServiceFee: self.additionalServiceFee,
        deliveryPickupOptions: self.deliveryPickupOptions
      }


      const res = await api_update_merchant_storefront(self.accountStore.isUserAdminOrSupport ? dataAdmin : dataMerchant, self.token, self.merchantStorefront.id)

      if (res.error) throw res
      toastToSuccess(ToasterMsgs.successStorefrontUpdated)
      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      toastToError(ToasterMsgs.genericError)
      console.log(err)
    }
  
},
  async getAndEditStorefront(merchantId) {
    try {
      self.set('loading', true)
      const res = await api_get_merchant_storefront(self.token, merchantId)

      if (res.error) throw res

      const ids = await self.merchantsFactory.addUpdateMerchant(res)

      self.clearInputs()
      self.set('merchantStorefront', ids)
      self.setStorefrontForEdit(res)

      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
    }
  },

  async getDeliveryZones() {
    try {
      self.set('loading', true)
      const res = await api_get_delivery_zones(self.token, self.merchantId)

      if (res.error) throw res

      self.merchantStorefront.set('deliveryZones', res.data)

      self.set('loading', false)
    } catch (err) {
      self.set('loading', false)
    }
  },

  async addDeliveryZone(zipCode, minOrderSize) {
    try {
      const res = await api_add_delivery_zone(self.token, {
        zipCode,
        minOrderSize,
        merchantId: self.merchantId
      })

      if (res.error) throw res

      // Add the new delivery zone to the store
      self.merchantStorefront.set("deliveryZones", [...toJS(self.merchantStorefront.deliveryZones), res.data])

      return res.data
    } catch (error) {
      console.error('Error adding delivery zone:', error)
      toastToError(error.response.data.details)

      throw error
    }
  },

  async updateDeliveryZone(id, minOrderSize) {
    try {
      const res = await api_update_delivery_zone(self.token, id, minOrderSize)

      if (res.error) throw res

      // Update the delivery zone in the store
      const index = self.merchantStorefront.deliveryZones.findIndex(dz => dz.id === id)
      if (index !== -1) {
        self.merchantStorefront.deliveryZones[index].update(res.data)
      }

      return res.data
    } catch (error) {
      console.error('Error updating delivery zone:', error)
      if (error.response?.data?.details) {
        toastToError(error.response.data.details)
      }
      throw error
    }
  },

  async deleteDeliveryZone(id) {
    try {
      const res = await api_remove_delivery_zone(self.token, id)

      if (res.error) throw res

      // Remove the delivery zone from the store
      const index = self.merchantStorefront.deliveryZones.findIndex(dz => dz.id === id)
      if (index !== -1) {
        self.merchantStorefront.set("deliveryZones", 
          self.merchantStorefront.deliveryZones.filter(dz => dz.id !== id)
        )
      }

      return res.data
    } catch (error) {
      console.error('Error deleting delivery zone:', error)
      if (error.response?.data?.details) {
        toastToError(error.response.data.details)
      }
      throw error
    }
  },

  async getAllDeliveryZones() {
    try {
      const res = await api_get_all_delivery_zones(self.token)

      if (res.error) throw res


      const ids = await self.merchantsFactory.addUpdateMerchants(res.data)

      console.log(ids)

      return res.data

      
    } catch (error) {
      console.error('Error getting delivery zones:', error)
      throw error
    }
  },

  set(key, value) {
    self[key] = value
  },
  setStorefrontForEdit(storefront) {

    self.set('deliveryRadius', storefront.deliveryRadius == null ? '1000' : storefront.deliveryRadius.toString())
    self.set('lat', storefront.location?.lat.toString())
    self.set('let', storefront.location?.lng.toString())
    self.set('lng', storefront.location?.lng.toString())
    self.set('legalName', storefront.legalName)
    self.set('email', storefront.email)
    self.set('notificationEmail', storefront.notificationEmail)
    self.set('phone', storefront.phone)
    //self.set('deliveryCommission', storefront.deliveryCommission.toString())
    self.set('deliveryCommission', "10")
    self.set('pickupCommission', storefront.pickupCommission)
    self.set('licenceNumber', storefront.licenceNumber)
    self.set('taxId', storefront.taxId)

    self.set('newLat', storefront.location?.lat)
    self.set('newLng', storefront.location?.lng)
    self.set('title', storefront.location?.title)
    self.set('streetNumber', storefront.location?.streetNumber)
    self.set('street', storefront.location?.street)
    self.set('address', storefront.location?.address)
    self.set('floor', storefront.location?.floor)
    self.set('city', storefront.location?.city)
    self.set('post', storefront.location?.post)
    self.set('state', storefront.location?.state)
    self.set('country', storefront.location?.country)
    self.set('realTimeDeliveryEstimation', storefront.realTimeDeliveryEstimation)
    self.set('additionalServiceFee', storefront.additionalServiceFee)
    self.set('deliveryPickupOptions', storefront.deliveryPickupOptions)


  },

  async updateLocation() {
    try {
      self.set('loading', true)

      const data = {
        deliveryRadius: Number(self.deliveryRadius),
        location: {
          // lat: self.merchantStorefront.location.lat,
          // lng: self.merchantStorefront.location.lng,
          // title: self.merchantStorefront.location.title,
          // streetNumber: self.merchantStorefront.location.streetNumber,
          // street: self.merchantStorefront.location.street,
          // address: self.merchantStorefront.location.address,
          // floor: self.merchantStorefront.location.floor,
          // city: self.merchantStorefront.location.city,
          // post: self.merchantStorefront.location.post,
          // state: self.merchantStorefront.location.state,
          // country: self.merchantStorefront.location.country
          lat: self.newLat,
          lng: self.newLng,
          title: self.merchantStorefront.location.title,
          streetNumber: self.streetNumber,
          street: self.street,
          address: self.address,
          floor: self.floor,
          city: self.city,
          post: self.post,
          state: self.state,
          country: self.country
        },
      }

      const res = await api_update_merchant_location(data, self.token, self.merchantStorefront.id)

      if (res.error) throw res
      toastToSuccess(ToasterMsgs.successStorefrontUpdated)
      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      toastToError(ToasterMsgs.genericError)
      console.log(err)
    }
  
},

})