export default {
  BLACK: '#000',
  WHITE: '#FFF',
  BRAND_DARK: '#082D0F',
  BRAND_GREEN: '#29BF12',
  GREY_GREEN: '#A3B7A7',
  BG_GREY: '#F3F3F3',
  BG_GREY_GREEN: '#B1C2B5',
  BG_GREY_GREEN_LIGHT: '#E9EEEA',
  STATUS_WARNING: '#E83F27',
  CONTENT_SHADOW: 'rgba(8, 45, 15, 0.15)',
  TRANSPARENT: 'transparent',
  BOATSWAIN: '#223354',
  ANTI_FLASH_WHITE: '#F2F3F5',
  BUDZ_MAIN_COLOR: '#00C262',
  BOAT_ANCHOR: '#6B6B6B',
  MAGIC_FOUNTAIN: '#1a75ff',
  GREY_DAWN: '#bdc2cc',
  NEW_YORK_PINK: "#DE7C8A",
  SKY_BLUE: "#E8F1FF",
  STRONG_BLUE: "#0061F4"
} 