import React, { useRef } from 'react'
import styled from 'styled-components'
import colors from '../../constants/colors'
import { v4 as uuid } from 'uuid'
import { Typography } from '@mui/material';

const Upload = (props) => {

  const id = useRef(uuid()) 
  const input = useRef(null)

  function onChange() {
    const files = input.current.files

  
    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        props.onChange({ 
          file: files[0],
          preview: e.target.result
        })
      }
      reader.readAsDataURL(files[0]);
    }

  }

  return (
    <Wrapper 
      data-for="upload"
      data-tip="Upload a picture of the item"
    >
      {props.label && <Label htmlFor={id.current}>{props.label}</Label>}
      <Uploader 
        photo={props.src}
        htmlFor={id.current}
      >
        {props.loading && <Loading>
          {/* <Placeholder>Uploading...{props.progress}%</Placeholder> */}
          <Typography variant="subtitle2">Uploading...{props.progress}%</Typography>
        </Loading>}

        {!props.loading && !props.src &&
          <IconWrap>
              <UploadImage
                src="/assets/upload-icon.png"
                alt="Upload icon"
                loading="lazy"
              />
            {/* <Icon src="/assets/icons/upload.svg" alt="Upload"/> */}
            {/* <Placeholder>Tap to select</Placeholder> */}
            <Typography variant="subtitle2">{props.title || "Upload picture"}</Typography>
            <Placeholder>{props.placeholder}</Placeholder>
          </IconWrap>
        }

        {!props.loading && props.src &&

          <Typography variant="subtitle2">Upload new image</Typography>
        }
      </Uploader>
      <input 
        ref={input}
        id={id.current} 
        accept="image"
        type="file" 
        onChange={onChange}
        style={{ position: 'absolute', top: -9999, opacity: 0 }}
      />
{/*       <ReactTooltip 
        id="upload"
        multiline={true}      
        effect="solid"
        place="bottom"
      /> */}
    </Wrapper>
  )
}

export default Upload

const Wrapper = styled.div`
  position: relative;
`
const UploadImage = styled.img`
  width: 32px;
`

const Uploader = styled.label`
  max-width: 277px;
  height: 118px;
  border-radius: 8px;
  background-color: ${colors.ANTI_FLASH_WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.photo});
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &:hover {
    opacity: .7
  }
  border: 1px dashed ${colors.ANTI_FLASH_WHITE};
`

const Label = styled.label`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.BLACK};
  display: block;
  margin-bottom: 2px;
`

const Loading = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .7);
`

const IconWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Placeholder = styled.p`
  margin: 0;
`