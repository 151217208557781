import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Card, 
  Divider, 
  CardContent, 
  Typography,
  Box,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import Upload from '../Upload/Upload';
import colors from '../../constants/colors';
import { useParams, useNavigate, useMatch } from 'react-router-dom';
import { api_create_banner, api_update_banner, api_get_banner_by_id, api_upload_banner_image } from '../../queries/banners';

const BannerForm = (props) => {
  const navigate = useNavigate();
  const match = useMatch('/support/banners/:id');
  const bannerId = match?.params?.id;
  const updating = !!bannerId;

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [status, setStatus] = useState('ACTIVE');
  const [action, setAction] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageUploading, setImageUploading] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Form validation
  useEffect(() => {
    const isValid = title.trim() !== '' && (imageUrl !== '' || imagePreview !== '');
    
    // Update the header component about form validity
    if (window.updateBannerFormValidity) {
      window.updateBannerFormValidity(isValid);
    }
  }, [title, imageUrl, imagePreview]);

  // Handle image upload
  const handleImageUpload = async (data) => {
    try {
      setImageUploading(true);
      setImagePreview(data.preview);
      
      const token = props.store.authStore.token;
      const response = await api_upload_banner_image(data.file, token);
      
      if (response && response.data) {
        setImageUrl(response.data.url);
        console.log('Banner image uploaded successfully:', response.data);
      }
    } catch (error) {
      console.error('Error uploading banner image:', error);
      setErrors({ ...errors, image: 'Failed to upload image' });
    } finally {
      setImageUploading(false);
    }
  };

  // Load banner data if editing
  useEffect(() => {
    const fetchBanner = async () => {
      if (bannerId) {
        try {
          setLoading(true);
          const token = props.store.authStore.token;
          const response = await api_get_banner_by_id(bannerId, token);
          
          if (response && response.data) {
            const banner = response.data;
            setTitle(banner.title || '');
            setSubtitle(banner.subtitle || '');
            setStatus(banner.status || 'ACTIVE');
            setAction(banner.action || '');
            setImageUrl(banner.image || '');
            setImagePreview(banner.image || '');
          }
        } catch (error) {
          console.error('Error fetching banner:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    
    fetchBanner();
  }, [bannerId, props.store.authStore.token]);

  // Submit form function
  const submitForm = async () => {
    try {
      // Validate
      const newErrors = {};
      if (!title.trim()) {
        newErrors.title = 'Title is required';
      }
      if (!imageUrl && !imagePreview) {
        newErrors.image = 'Image is required';
      }
      
      setErrors(newErrors);
      
      if (Object.keys(newErrors).length > 0) {
        return false;
      }
      
      const token = props.store.authStore.token;
      const bannerData = {
        title,
        subtitle,
        status,
        action: action || null,
        image: imageUrl
      };
      
      if (updating) {
        await api_update_banner(bannerData, bannerId, token);
      } else {
        await api_create_banner(bannerData, token);
      }
      
      navigate('/support/banners');
      return true;
    } catch (error) {
      console.error('Error saving banner:', error);
      return false;
    }
  };

  // Expose submit function to header component
  useEffect(() => {
    window.submitBannerForm = submitForm;
    
    return () => {
      window.submitBannerForm = undefined;
    };
  }, [title, subtitle, status, action, imageUrl, bannerId]);

  function getStatusExplanation(status) {
    switch (status) {
      case 'ACTIVE':
        return 'Banner is visible and will be displayed in the app.';
      case 'DISABLED':
        return 'Banner is disabled and will not be displayed in the app.';
      default:
        return '';
    }
  }

  function getActionExplanation(action) {
    switch (action) {
      case 'REFERRAL':
        return 'Banner will open the referral system when tapped.';
      case '':
      case null:
        return 'Banner will not perform any action when tapped.';
      default:
        return '';
    }
  }


  return (
    <Card>
      <CardContent>
        <Box paddingTop={"20px"}>
          <Typography fontWeight={"700"}>
            Banner Image
          </Typography>
          <Upload 
            label=""
            onChange={handleImageUpload}
            loading={imageUploading}
            src={imagePreview}
          />
          {imageUploading && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              <Typography variant="body2">Uploading image...</Typography>
            </Box>
          )}
          {errors.image && (
            <FormHelperText error>{errors.image}</FormHelperText>
          )}
          {imageUrl && (
            <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
              Image uploaded successfully
            </Typography>
          )}
        </Box>
        <Box sx={{ paddingBottom: "16px", paddingTop: "19px", maxWidth: "500px" }}>
          <TextField
            id="title"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={e => setTitle(e.target.value)}
            error={!!errors.title}
            helperText={errors.title}
          />
        </Box>
        <Box sx={{ paddingBottom: "16px", paddingTop: "19px", maxWidth: "500px" }}>
          <TextField
            id="subtitle"
            label="Subtitle"
            type="text"
            fullWidth
            value={subtitle}
            onChange={e => setSubtitle(e.target.value)}
          />
        </Box>

        <Box sx={{ paddingTop: "20px", paddingBottom: "20px", maxWidth: "500px" }}>
          <FormControl fullWidth>
            <InputLabel id="banner-status-label">Banner Status</InputLabel>
            <Select
              labelId="banner-status-label"
              id="banner-status"
              label={'Banner Status'}
              onChange={e => setStatus(e.target.value)}
              value={status}
            >
              <MenuItem value={"ACTIVE"}>ACTIVE - visible in the app</MenuItem>
              <MenuItem value={"INACTIVE"}>INACTIVE - hidden from the app</MenuItem>
              <MenuItem value={"DISABLED"}>DISABLED - disabled and hidden</MenuItem>
            </Select>
          </FormControl>
          <Typography
            sx={{
              margin: "5px 0",
              color: 'lightgray',
              fontStyle: 'italic'
            }}
          >{getStatusExplanation(status)}</Typography>
        </Box>
        
        <Box sx={{ paddingTop: "0px", maxWidth: "500px" }}>
          <FormControl fullWidth>
            <InputLabel id="banner-action-label">Banner Action</InputLabel>
            <Select
              labelId="banner-action-label"
              id="banner-action"
              label={'Banner Action'}
              onChange={e => setAction(e.target.value)}
              value={action}
            >
              <MenuItem value={""}>None - No action</MenuItem>
              <MenuItem value={"REFERRAL"}>REFERRAL - Open referral system</MenuItem>
            </Select>
          </FormControl>
          <Typography
            sx={{
              margin: "5px 0",
              color: 'lightgray',
              fontStyle: 'italic'
            }}
          >{getActionExplanation(action)}</Typography>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default inject('store')(observer(BannerForm));
