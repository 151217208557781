import { useEffect, useState } from 'react';
import {
  Tooltip,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../../components/Modals/AlertDialog';
import Label from '../../../components/Label';
import { api_delete_banner } from '../../../queries/banners';

const BannersTable = (props) => {
  const theme = useTheme();
  const Navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBannerId, setSelectedBannerId] = useState(null);
  const { banners = [], loading = false } = props;

  const handleClickEdit = (banner) => {
    Navigate(`/support/banners/${banner.id}`);
  };

  const handleClickDelete = (banner) => {
    setSelectedBannerId(banner.id);
    setOpenDialog(true);
  };

  const handleDisagree = () => {
    setOpenDialog(false);
  };

  const handleAgree = async () => {
    setOpenDialog(false);
    try {
      const token = props.store.authStore.token;
      await api_delete_banner(selectedBannerId, token);
      // Refresh the banners list
      window.location.reload();
    } catch (error) {
      console.error('Error deleting banner:', error);
    }
  };

  function getStatusColor(status) {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'INACTIVE':
      case 'DISABLED':
        return 'error';
      default:
        return 'warning';
    }
  }


  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Action</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banners.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body1">No banners found</Typography>
                </TableCell>
              </TableRow>
            ) : (
              banners?.map((item) => {
                return (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {item.id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell 
                      width="100px" 
                      onClick={() => handleClickEdit(item)} 
                      sx={{ 
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          backgroundColor: theme.colors.primary.lighter,
                          transform: 'translateY(-1px)',
                          boxShadow: '0 5px 10px rgba(0,0,0,0.1)'
                        }
                      }}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        {item.image && (
                          <img 
                            src={item.image} 
                            alt={item.title} 
                            style={{ width: '100px', height: '100px', objectFit: 'cover', borderTopLeftRadius: '18px', borderBottomLeftRadius: '18px' }}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell 
                      onClick={() => handleClickEdit(item)} 
                      sx={{ 
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          backgroundColor: theme.colors.primary.lighter
                        }
                      }}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {item.title}
                        </Typography>
                      </Box>
                      <Typography
                          variant="body1"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {item.subtitle}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          variant="body1"
                          fontWeight="normal"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {item.action ? item.action : 'None'}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                        <Typography
                          variant="body1"
                          fontWeight="normal"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          <Label color={getStatusColor(item.status)}>
                            {item.status}
                          </Label>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog 
        open={openDialog}
        handleDisagree={handleDisagree}
        handleAgree={handleAgree}
        title={"Do you really want to delete this banner?"}
        description={"You are about to delete this banner and lose all information connected with it."}
      />
    </Card>
  );
};

export default inject('store')(observer(BannersTable));
