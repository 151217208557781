import { types } from "mobx-state-tree";
import * as actions from './TimeSlotActions';
import * as views from './TimeSlotViews';

const TimeSlot = types
  .model('TimeSlot', {
    id: types.identifierNumber,
    dayOfTheWeek: types.maybeNull(types.number),
    startTime: types.maybeNull(types.string),
    endTime: types.maybeNull(types.string),
    type: types.maybeNull(types.enumeration(['PICKUP', 'DELIVERY', 'ALL']))
  })
  .views(views.timeSlotViews)
  .actions(actions.timeSlotActions);

export default TimeSlot;
