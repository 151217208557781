import { _add_update_objects } from '../../../services/factories-service';

export const timeSlotsFactoryActions = self => ({
  addUpdateTimeSlots(timeSlots) {
    return _add_update_objects(timeSlots, self.timeSlots, self.parseTimeSlot);
  },
  
  addUpdateTimeSlot(timeSlot) {
    const ids = self.addUpdateTimeSlots([timeSlot]);
    return ids[0];
  },
  
  parseTimeSlot(timeSlot) {
    return {
      ...timeSlot,
    };
  }
});
