import { types } from "mobx-state-tree";
import TimeSlot from "../../models/timeSlot/timeSlot";
import * as actions from './timeSlotsFactoryActions';
import * as views from './timeSlotsFactoryViews';

const TimeSlotsFactory = types
  .model('TimeSlotsFactory', {
    timeSlots: types.optional(
      types.array(TimeSlot), []
    )
  })
  .views(views.timeSlotsFactoryViews)
  .actions(actions.timeSlotsFactoryActions);

export default TimeSlotsFactory;
