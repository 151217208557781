import { Typography, Button, Grid, Box } from '@mui/material';
import * as React from 'react';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useNavigate } from 'react-router-dom';

function BannersPageHeader() {
  const Navigate = useNavigate();

  const handleClick = () => {
    Navigate('/support/add-banner');
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Banners
        </Typography>
      </Grid>
      <Grid item>
        <Box display="flex" gap={"10px"} flexWrap={"wrap"}>
          <Button
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
            onClick={handleClick}
          >
            Add Banner
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default BannersPageHeader;
