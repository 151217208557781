import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import TakeoutDiningTwoToneIcon from '@mui/icons-material/TakeoutDiningTwoTone';
import MopedTwoToneIcon from '@mui/icons-material/MopedTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import StarRateTwoToneIcon from '@mui/icons-material/StarRateTwoTone';
import SellTwoToneIcon from '@mui/icons-material/SellTwoTone';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import { MapTwoTone } from '@mui/icons-material';
import AbcTwoToneIcon from '@mui/icons-material/AbcTwoTone';

const support = {
    heading: 'Admin',
    items: [
      {
        name: 'Analytics',
        icon: AnalyticsTwoToneIcon,
        link: '/support/heatmaps'
      },
      {
        name: 'Merchants',
        icon: StorefrontTwoToneIcon,
        link: '/support/merchants'
      },
      {
        name: 'Drivers',
        icon: MopedTwoToneIcon,
        link: '/support/drivers'
      },
      {
        name: 'Orders',
        icon: TakeoutDiningTwoToneIcon,
        link: '/support/orders'
      },
      {
        name: 'Users',
        icon: PeopleAltTwoToneIcon,
        link: '/support/users'
      },
      {
        name: 'Effects',
        icon: StarRateTwoToneIcon,
        link: '/support/effects'
      },
      {
        name: 'Categories',
        icon: CategoryTwoToneIcon,
        link: '/support/categories'
      },
      {
        name: 'Promotions',
        icon: SellTwoToneIcon,
        link: '/support/promotions'
      },
      {
        name: 'Banners',
        icon: AbcTwoToneIcon,
        link: '/support/banners'
      },
      {
        name: 'System Settings',
        icon: SettingsTwoToneIcon,
        link: '/support/settings'
      }
    ]
  }

export default support;
