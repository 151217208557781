import { Helmet } from 'react-helmet-async';
import BannersHeader from './BannersHeader';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import { Container, Grid } from '@mui/material';
import Footer from '../../../components/Footer';

import BannersTable from './BannersTable';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { api_get_all_banners } from '../../../queries/banners';
import { inject, observer } from 'mobx-react';

function BannersPage(props) {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  const handleClick = () => {
    Navigate('/support/add-banner');
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        setLoading(true);
        const token = props.store.authStore.token;
        const response = await api_get_all_banners(token);
        
        if (response && response.data) {
          setBanners(response.data.rows);
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchBanners();
  }, [props.store.authStore.token]);

  return (
    <>
      <Helmet>
        <title>Banners</title>
      </Helmet>
      <PageTitleWrapper>
        <BannersHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <BannersTable banners={banners} loading={loading} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default inject('store')(observer(BannersPage));
