import find from 'lodash/find'
import { api_get_product_by_id } from '../../../queries/products'
import { _add_update_objects } from '../../../services/factories-service'
import { api_get_order_by_id } from '../../../queries/orders'

export const ordersFactoryActions = self => ({
  addUpdateOrders(orders) {
    return _add_update_objects(orders, self.orders, self.parseOrders)
  },
  addUpdateOrder(p) {
    const ids = self.addUpdateOrders([p])
    return ids[0]
  },
  parseOrders(o) {

    return ({
      ...o,
      products: Array.isArray(o.products) && o.products.length ? self.productsFactory.addUpdateProducts(o.products) : null,
      deliveryTasks: Array.isArray(o.deliveryTasks) && o.deliveryTasks.length ? self.deliveryTasksFactory.addUpdateDeliveryTasks(o.deliveryTasks) : null,
      buyer: o.buyer?.id ? self.usersFactory.addUpdateUser(o.buyer) : null,
      merchant: o.merchant?.id ? self.merchantsFactory.addUpdateMerchant(o.merchant) : null,
      deliveryLocation: o.deliveryLocation?.id ? self.locationsFactory.addUpdateLocation(o.deliveryLocation) : null,
      rating: o.rating?.id ? self.feedbacksFactory.addUpdateFeedback(o.rating) : null,
      coupon: o.coupon?.id ? self.couponsFactory.addUpdateCoupon(o.coupon) : null,
      timeSlot: o.timeSlot?.id ? self.timeSlotsFactory.addUpdateTimeSlot(o.timeSlot) : null,
    })
  },
  async getOrder(mId) {
    try {
      const id = parseInt(mId)

      let order = find(self.orders, p => p.id === id)
      if (order) return order

      const res = await self.fetch(id)
      if (res.error) throw res

      return find(self.orders, p => p.id === id)
    } catch (err) {
      return {
        ...err,
        error: true
      }
    }
  },
  async fetch(id) {
    try {
      const res = await api_get_order_by_id(id)
      if (res.error) throw res

      self.addUpdateOrder(res)

      return res
    } catch (err) {
      return err
    }
  },
})