import find from 'lodash/find'
import filter from 'lodash/filter'

export const orderActions = self => ({
  getCurrentProduct(id) {
    return find(self.quantities, q => q.id === id)
  },
  getProductQuantity(id) {
    return filter(self.quantities, q => q.id === id)[0]?.quantity
  },
  update(data) {
    self.set('totalAmount', data.totalAmount)
    self.set('tax', data.tax)
    self.set('deliveryFee', data.deliveryFee)
    self.set('otherFees', data.otherFees)
    self.set('serviceFee', data.serviceFee)
    self.set('tip', data.tip)
    self.set('promoCode', data.promoCode)
    self.set('instructions', data.instructions)
    self.set('status', data.status)
    self.set('publicOrderId', data.publicOrderId)
    self.set('merchant', data.merchant)
    self.set('subtotal', data.subtotal)
    self.set('products', data.products)
    self.set('quantities', data.quantities)
    self.set('buyer', data.buyer)
    self.set('merchant', data.merchant)
    if (data.coupon)
      self.set('coupon', data.coupon)
    self.set('deliveryLocation', data.deliveryLocation)
    self.set('logs', data.logs)
    self.set('orderDate', data.orderDate)
    self.set('driverPaid', data.driverPaid)
    self.set('merchantPaid', data.merchantPaid)
    self.set('rating', data.rating)
    self.set('invoiceLink', data.invoiceLink)
    self.set('deliveryTasks', data.deliveryTasks)
    self.set('deliveryType', data.deliveryType)
    self.set('settled', data.settled)
    self.set('platformCommissionAmount', data.platformCommissionAmount)
    self.set('platformCommissionPercent', data.platformCommissionPercent)
    if (data.timeSlotId)
      self.set('timeSlotId', data.timeSlotId)
    if (data.timeSlot) 
      self.set('timeSlot', data.timeSlot)
  },
  set(key, value) {
    self[key] = value
  }
})