import { Grid, Box, Tooltip, IconButton } from '@mui/material';
import * as React from 'react';
import PageTitle from '../../../components/PageTitle/index';
import { inject, observer } from 'mobx-react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';

function EditBannerHeader(props) {
  const [loading, setLoading] = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);

  const Navigate = useNavigate();

  // This function will be called from the form component
  React.useEffect(() => {
    // We'll add form validation checks later
    window.updateBannerFormValidity = (isValid) => {
      setIsFormValid(isValid);
    };

    return () => {
      window.updateBannerFormValidity = undefined;
    };
  }, []);

  const handleClickUpdate = async () => {
    if (window.submitBannerForm && typeof window.submitBannerForm === 'function') {
      setLoading(true);
      try {
        const success = await window.submitBannerForm();
        if (success) {
          Navigate('/support/banners');
        }
      } catch (error) {
        console.error('Error updating banner:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGoBack = () => {
    Navigate('/support/banners');
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display="flex" mb={3}>
          <Tooltip arrow placement="top" title="Go back">
            <IconButton color="primary" sx={{ p: 2, mr: 2 }} onClick={handleGoBack}>
              <ArrowBackTwoToneIcon />
            </IconButton>
          </Tooltip>
          <PageTitle
            heading="Banner"
            subHeading="Edit existing banner"
          />
        </Box>
      </Grid>
      <Box display="flex" gap={"10px"} flexWrap={"wrap"}>
        <LoadingButton
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          disabled={!isFormValid}
          loading={loading}
          onClick={handleClickUpdate}
        >
          Update Banner
        </LoadingButton>
      </Box>
    </Grid>
  );
}

export default inject('store')(observer(EditBannerHeader));
