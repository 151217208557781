import { getRoot } from "mobx-state-tree"

export const timeSlotViews = self => ({
  get root() {
    return getRoot(self)
  },
  get fullTimeSlotFormatted() {
    if (!self.dayOfTheWeek || !self.startTime || !self.endTime) return "";
    
    // Format day of week (0-Monday, 6-Sunday)
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const dayName = daysOfWeek[self.dayOfTheWeek];
    
    // Format time (0900 → 9:00 AM, 1500 → 3:00 PM)
    const formatTime = (timeStr) => {
      if (!timeStr || timeStr.length !== 4) return "";
      
      const hours = parseInt(timeStr.substring(0, 2));
      const minutes = timeStr.substring(2, 4);
      const period = hours >= 12 ? "PM" : "AM";
      const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12 AM
      
      return `${hours12}:${minutes} ${period}`;
    };
    
    const startFormatted = formatTime(self.startTime);
    const endFormatted = formatTime(self.endTime);
    
    return `${dayName}, ${startFormatted} - ${endFormatted}`;
  }
})
