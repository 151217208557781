import { API } from "../constants/system";
import { _throw } from "../utils/error-service";
import axios from 'axios';

export async function api_get_all_time_slots(token, merchantId) {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API}/cms/scheduled-slots/${merchantId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            }
        });

        return response.data;
    } catch (err) {
        return _throw(err)
    }
}

export async function api_create_time_slots(token, merchantId, data) {
    try {
        const response = await axios({
            method: 'POST',
            url: `${API}/cms/scheduled-slots/${merchantId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            data: data
        });

        return response.data;
    } catch (err) {
        return _throw(err)
    }
}

export async function api_update_time_slots(token, merchantId, data) {
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API}/cms/scheduled-slots/${merchantId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            data: data
        });

        return response.data;
    } catch (err) {
        return _throw(err)
    }
}

export async function api_delete_time_slots(token, merchantId, data) {
    try {

        console.log(data)

        const response = await axios({
            method: 'DELETE',
            url: `${API}/cms/scheduled-slots/${merchantId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            data: data
        });

        return response.data;
    } catch (err) {
        return _throw(err)
    }
}