import { api_get_all_time_slots, api_create_time_slots, api_delete_time_slots, api_update_time_slots } from "../../../queries/timeSlots"

export const timeSlotsStoreActions = self => ({   
  async getTimeSlots() {
    try {
      self.set('loading', true)
      const res = await api_get_all_time_slots(self.token, self.merchantId)

      console.log(res)

      if (res.error) throw res

      const ids = await self.timeSlotsFactory.addUpdateTimeSlots(res.data)

      console.log(ids)

      self.set('timeSlots', ids)
      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },

  async createTimeSlots(data) {
    try {
      self.set('updating', true)
      const res = await api_create_time_slots(self.token, self.merchantId, data)

      console.log(res)

      if (res.error) throw res

      // After successful creation, refresh the time slots
      await self.getTimeSlots()
      
      self.set('updating', false)
      return res.data
      
    } catch (err) {
      self.set('updating', false)
      console.log(err)
      return { error: err }
    }
  },

  async updateTimeSlots(data) {
    try {
      self.set('updating', true)
      const res = await api_update_time_slots(self.token, self.merchantId, data)

      console.log(res)

      if (res.error) throw res

      // After successful update, refresh the time slots
      await self.getTimeSlots()
      
      self.set('updating', false)
      return res.data
      
    } catch (err) {
      self.set('updating', false)
      console.log(err)
      return { error: err }
    }
  },

  async deleteTimeSlots(data) {
    try {
      self.set('updating', true)
      const res = await api_delete_time_slots(self.token, self.merchantId, data)

      console.log(res)

      if (res.error) throw res

      // After successful deletion, refresh the time slots
      await self.getTimeSlots()
      
      self.set('updating', false)
      return res.data
      
    } catch (err) {
      self.set('updating', false)
      console.log(err)
      return { error: err }
    }
  },

  set(key, value) {
    self[key] = value
  }
})
