import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_get_all_banners = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/banners`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_active_banners = async () => {
  try {
    const res = await axios.get(`${API}/cms/banners/active`)
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_banner_by_id = async (bannerId, token) => {
  try {
    const res = await axios.get(`${API}/cms/banners/${bannerId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_upload_banner_image = async (file, token) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    
    const res = await axios.post(`${API}/assets?fileType=BANNER`, formData, {
      headers: {
        'Authorization': token,
        'Content-Type': 'multipart/form-data'
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_create_banner = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/banners`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_banner = async (data, bannerId, token) => {
  try {
    const res = await axios.put(`${API}/cms/banners/${bannerId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_delete_banner = async (bannerId, token) => {
  try {
    const res = await axios.delete(`${API}/cms/banners/${bannerId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}
