import { getRoot } from "mobx-state-tree"

export const timeSlotsStoreViews = self => ({
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  },
  get timeSlotsFactory() {
    return getRoot(self).timeSlotsFactory
  }
})
