import { types } from 'mobx-state-tree'
import TimeSlot from '../../models/timeSlot/timeSlot'
import * as views from './timeSlotsStoreViews'
import * as actions from './timeSlotsStoreActions'

const TimeSlotsStore = types
  .model('timeSlotsStore', {
    timeSlots: types.array(types.reference(TimeSlot)),
    updating: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false)
  })
  .views(views.timeSlotsStoreViews)
  .actions(actions.timeSlotsStoreActions)

export default TimeSlotsStore
