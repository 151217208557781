export const timeSlotActions = self => ({
  update(data) {
    self.set('dayOfTheWeek', data.dayOfTheWeek)
    self.set('startTime', data.startTime)
    self.set('endTime', data.endTime)
    self.set('type', data.type)
  },
  set(key, value) {
    self[key] = value
  }
})
