import PropTypes from 'prop-types';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Typography, Button, Grid, Badge, Box, CircularProgress } from '@mui/material';
import { Tag } from '@mui/icons-material';
import Label from '../Label';

const PageTitle = ({
    heading = '',
    subHeading = '',
    docs = '',
    ...rest
}) => {

    return (
        <Grid container justifyContent="space-between" alignItems="flex-start" {...rest}>
            <Grid item>
                <Grid sx={{ 
                    flexDirection: {
                        xs: "column",
                        sm: "row"
                    }
                }} container justifyContent="flex-start" alignItems="flex-start">
                    <Typography sx={{ maxWidth: "450px", order: 1 }} variant="h3" component="h3" gutterBottom>
                        {heading}
                    </Typography>
                    {rest.tag && rest.tag.label ? <>
                        <Box 
                            sx={{ marginLeft: {xs: "0", sm: "10px"}, order: {
                                xs: 0,
                                sm: 2
                            } }}
                        >
                            <Label color={rest.tag.color}>
                                {rest.tag.loading && <CircularProgress color={rest.tag.color} sx={{ marginRight: "8px" }} size={15} thickness={5} />}
                                {rest.tag.label}
                            </Label>
                        </Box>
                    </> : null}
                </Grid>

                <Typography variant="subtitle2">
                    {subHeading}
                </Typography>
            </Grid>

            
        </Grid>
    );
};

PageTitle.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    docs: PropTypes.string,
};

export default PageTitle;
